<template>
    <div class="min-h-screen bg-gray-50 text-gray-800 p-8">
        <h1 class="block w-full text-center text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg
               bg-gradient-to-r from-gray-500 to-blue-500 text-transparent bg-clip-text">
            {{ isUpdate ? 'Atualizar Usuário' : 'Criar Usuário' }}
        </h1>
        <div class="flex flex-col md:flex-row justify-between items-center mt-4 gap-4">
            <button v-if="!showForm" @click="openForm"
                class="bg-gray-600 text-white px-6 py-3 rounded-lg font-semibold transition-colors duration-300 hover:bg-gray-700">
                {{ isUpdate ? 'Atualizar Usuário' : 'Criar Novo Usuário' }}
            </button>
            <div class="w-full md:w-1/3">
                <input id="search" v-model="searchTerm" type="text" placeholder="Pesquisar Usuário"
                    class="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500" />
            </div>
        </div>

        <div v-if="filteredUsers.length" class="overflow-x-auto mt-6">
            <table class="min-w-full bg-white border border-gray-200">
                <thead class="bg-gray-100 border-b">
                    <tr>
                        <th class="py-3 px-4 text-left text-sm font-medium text-gray-700">Nome</th>
                        <th class="py-3 px-4 text-left text-sm font-medium text-gray-700">Status</th>
                        <th class="py-3 px-4 text-left text-sm font-medium text-gray-700">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in filteredUsers" :key="user.id"
                        class="border-b hover:bg-gray-50 transition-colors">
                        <td class="py-3 px-4">
                            {{ user.name }} ({{ user.isActive ? 'Ativo' : 'Inativo' }})
                        </td>
                        <td class="py-3 px-4">
                            <span :class="user.isActive ? 'text-green-600' : 'text-red-600'">
                                {{ user.isActive ? 'Ativo' : 'Inativo' }}
                            </span>
                        </td>
                        <td class="py-3 px-4">
                            <div class="flex space-x-4 items-center">
                                <button @click="editUser(user)" class="text-blue-600 hover:underline">
                                    Editar
                                </button>
                                <button @click="confirmDeleteUser(user.id)" class="text-red-600 hover:underline">
                                    Deletar
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <p v-else class="mt-4 text-center text-gray-600">
            Nenhum usuário encontrado.
        </p>

        <transition name="fade">
            <div v-if="showForm" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div class="bg-white w-full max-w-xl mx-4 p-6 rounded-lg shadow-lg relative">
                    <button @click="closeForm"
                        class="absolute top-3 right-3 text-gray-600 hover:text-gray-900 text-2xl">
                        &times;
                    </button>
                    <h2 class="text-center text-2xl font-bold mb-4
                     bg-gradient-to-r from-gray-500 to-blue-500 text-transparent bg-clip-text">
                        {{ isUpdate ? 'Atualizar Usuário' : 'Criar Usuário' }}
                    </h2>
                    <form @submit.prevent="handleSubmit" class="space-y-5">
                        <div>
                            <label for="name" class="block text-sm font-medium text-gray-600">
                                Nome
                            </label>
                            <input id="name" v-model="form.name" type="text" class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm
                         focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" required />
                        </div>
                        <div>
                            <label for="email" class="block text-sm font-medium text-gray-600">
                                Email
                            </label>
                            <input id="email" v-model="form.email" type="email" class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm
                         focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" required />
                        </div>
                        <div>
                            <label for="username" class="block text-sm font-medium text-gray-600">
                                Nome de Usuário
                            </label>
                            <input id="username" v-model="form.username" type="text" class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm
                         focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" required />
                        </div>
                        <div>
                            <label for="role" class="block text-sm font-medium text-gray-600">
                                Papel do Usuário
                            </label>
                            <select id="role" v-model="form.role" class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm
                         focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" required>
                                <option value="" disabled>Selecione o papel do usuário</option>
                                <option value="ADMIN">Admin</option>
                                <option value="USER">User</option>
                            </select>
                        </div>
                        <div>
                            <label for="password" class="block text-sm font-medium text-gray-600">
                                Senha
                            </label>
                            <input id="password" v-model="form.password" type="password" class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm
                         focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                :required="!isUpdate" />
                        </div>
                        <button type="submit" class="bg-gray-600 text-white px-4 py-2 rounded-md w-full font-semibold
                       hover:bg-gray-700 transition duration-300">
                            {{ isUpdate ? 'Atualizar' : 'Criar' }}
                        </button>
                    </form>
                    <p v-if="statusMessage" class="mt-4 text-center text-red-500">
                        {{ statusMessage }}
                    </p>
                </div>
            </div>
        </transition>
    </div>
</template>
  
  <script setup>
import { ref, computed, onMounted } from 'vue'
import axios from 'axios'

const form = ref({
    name: '',
    email: '',
    username: '',
    password: '',
    role: ''
})
const isUpdate = ref(false)
const showForm = ref(false)
const statusMessage = ref('')
const users = ref([])
const searchTerm = ref('')
const editingUserId = ref('')

const openForm = () => {
    isUpdate.value = false
    form.value = { name: '', email: '', username: '', password: '', role: '' }
    showForm.value = true
}

const closeForm = () => {
    showForm.value = false
    isUpdate.value = false
    editingUserId.value = ''
    form.value = { name: '', email: '', username: '', password: '', role: '' }
}

const handleSubmit = async () => {
    try {
        if (isUpdate.value) {
            const updatedForm = { ...form.value }
            if (!updatedForm.password) {
                delete updatedForm.password
            }
            await axios.put(`https://whatsapp.chatease.app/users/${editingUserId.value}`, updatedForm)
            statusMessage.value = 'Usuário atualizado com sucesso!'
        } else {
            await axios.post('https://whatsapp.chatease.app/users', form.value)
            statusMessage.value = 'Usuário criado com sucesso!'
        }
        closeForm()
        await fetchUsers()
    } catch (error) {
        statusMessage.value = 'Ocorreu um erro ao processar a solicitação.'
    }
}

const fetchUsers = async () => {
    try {
        const response = await axios.get('https://whatsapp.chatease.app/users')
        users.value = response.data
    } catch (error) {
        statusMessage.value = 'Erro ao buscar usuários.'
    }
}

const editUser = (user) => {
    form.value = {
        name: user.name,
        email: user.email,
        username: user.username,
        password: '',
        role: user.role
    }
    isUpdate.value = true
    showForm.value = true
    editingUserId.value = user.id
}

const confirmDeleteUser = (id) => {
    if (confirm('Tem certeza que deseja deletar este usuário?')) {
        deleteUser(id)
    }
}

const deleteUser = async (id) => {
    try {
        await axios.delete(`https://whatsapp.chatease.app/users/${id}`)
        statusMessage.value = 'Usuário deletado com sucesso!'
        await fetchUsers()
    } catch (error) {
        statusMessage.value = 'Erro ao deletar usuário.'
    }
}

const filteredUsers = computed(() => {
    const term = searchTerm.value.toLowerCase()
    return users.value.filter(user =>
        user.name.toLowerCase().includes(term) ||
        user.email.toLowerCase().includes(term) ||
        user.username.toLowerCase().includes(term)
    )
})

onMounted(async () => {
    await fetchUsers()
})
</script>
  
  <style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
  