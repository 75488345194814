<template>
    <div class="w-full min-h-screen bg-gray-50 text-gray-800 p-8">
        <h1
            class="block w-full text-center text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg bg-gradient-to-r from-gray-500 to-blue-500 text-transparent bg-clip-text">
            {{ isUpdate ? 'Atualizar Número' : 'Cadastrar Número' }}
        </h1>
        <br />
        <div class="flex justify-start mb-6">
            <button v-if="!showForm" @click="openCreateForm"
                class="bg-gray-600 text-white px-6 py-3 rounded-lg font-semibold transition-colors duration-300 hover:bg-gray-700">
                {{ isUpdate ? 'Atualizar Número' : 'Cadastrar Novo Número' }}
            </button>
        </div>

        <p v-if="statusMessage" class="mt-2 text-center text-red-500">
            {{ statusMessage }}
        </p>

        <div class="my-5 w-full">
            <label for="pesquisaNumero" class="block text-gray-700 text-sm font-medium mb-2">
                Pesquisar:
            </label>
            <input id="pesquisaNumero" v-model="termoPesquisa"
                class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Pesquise..." />
        </div>

        <div v-if="numerosFiltrados.length" class="overflow-x-auto mt-4">
            <table class="min-w-full bg-white border border-gray-200">
                <thead class="bg-gray-100 border-b border-gray-200">
                    <tr>
                        <th class="py-3 px-4 text-left text-sm font-medium text-gray-700">Número</th>
                        <th class="py-3 px-4 text-left text-sm font-medium text-gray-700">Empresa</th>
                        <th class="py-3 px-4 text-left text-sm font-medium text-gray-700">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="numero in numerosFiltrados" :key="numero.id"
                        class="border-b last:border-0 hover:bg-gray-50 transition-colors">
                        <td class="py-3 px-4 text-gray-800 font-semibold">{{ numero.numero }}</td>
                        <td class="py-3 px-4 text-gray-800">{{ getEmpresaNome(numero.empresaId) }}</td>
                        <td class="py-3 px-4">
                            <div class="flex space-x-4 text-sm">
                                <button @click="editNumero(numero)" class="text-blue-600 hover:underline">
                                    Editar
                                </button>
                                <button @click="confirmDeleteNumero(numero.id)" class="text-red-600 hover:underline">
                                    Deletar
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p v-else class="mt-4 text-center text-gray-600">Nenhum número cadastrado.</p>

        <transition name="fade">
            <div v-if="showForm" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div class="bg-white w-full max-w-2xl mx-4 p-6 rounded-lg shadow-lg relative">
                    <button @click="closeForm"
                        class="absolute top-3 right-3 text-gray-600 hover:text-gray-900 text-2xl">
                        &times;
                    </button>
                    <h2
                        class="text-center text-2xl font-bold mb-4 bg-gradient-to-r from-gray-500 to-blue-500 text-transparent bg-clip-text">
                        {{ isUpdate ? 'Atualizar Número' : 'Cadastrar Número' }}
                    </h2>
                    <form @submit.prevent="handleSubmit" class="grid grid-cols-1 gap-6">
                        <div>
                            <label for="numero" class="block text-sm font-medium text-gray-600">
                                Número de Telefone
                            </label>
                            <input id="numero" v-model="form.numero" type="text" required
                                class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                        </div>
                        <div v-if="isAdmin">
                            <label for="empresaId" class="block text-sm font-medium text-gray-600">
                                Empresa
                            </label>
                            <select id="empresaId" v-model="form.empresaId" required
                                class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                <option value="" disabled>Selecione uma empresa</option>
                                <option v-for="empresa in empresas" :key="empresa.id" :value="empresa.id">
                                    {{ empresa.nome }}
                                </option>
                            </select>
                        </div>
                        <div>
                            <button type="submit"
                                class="bg-gray-600 text-white px-4 py-2 rounded-md w-full font-semibold hover:bg-gray-700 transition duration-300">
                                {{ isUpdate ? 'Atualizar Número' : 'Cadastrar Número' }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import axios from 'axios'

const form = ref({
    numero: '',
    empresaId: ''
})
const isUpdate = ref(false)
const showForm = ref(false)
const statusMessage = ref('')
const isAdmin = ref(false)
const numeros = ref([])
const empresas = ref([])
const editingNumeroId = ref('')
const termoPesquisa = ref('')


const checkUserRole = async () => {
    try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.token) {
            const response = await axios.get('https://whatsapp.chatease.app/auth/me', {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            isAdmin.value = response.data.role === 'ADMIN'
        } else {
            statusMessage.value = 'Você precisa estar logado.'
        }
    } catch (error) {
        console.error('Erro ao verificar papel do usuário:', error)
        statusMessage.value = 'Erro ao verificar papel do usuário.'
    }
}

const fetchUserEmpresa = async () => {
    try {
        const user = JSON.parse(localStorage.getItem('user'))
        const response = await axios.get('https://whatsapp.chatease.app/empresas/minhas', {
            headers: { Authorization: `Bearer ${user.token}` }
        })
        const userEmpresa = response.data[0]
        if (userEmpresa) {
            form.value.empresaId = userEmpresa.id
        } else {
            statusMessage.value = 'Você não possui uma empresa associada.'
        }
    } catch (error) {
        console.error('Erro ao buscar empresa do usuário:', error)
        statusMessage.value = 'Erro ao buscar empresa do usuário.'
    }
}

const fetchEmpresas = async () => {
    try {
        const user = JSON.parse(localStorage.getItem('user'))
        const response = await axios.get('https://whatsapp.chatease.app/empresas', {
            headers: { Authorization: `Bearer ${user.token}` }
        })
        empresas.value = response.data
    } catch (error) {
        console.error('Erro ao buscar empresas:', error)
        statusMessage.value = 'Erro ao buscar empresas.'
    }
}

const fetchNumeros = async () => {
    try {
        const user = JSON.parse(localStorage.getItem('user'))
        const response = await axios.get('https://whatsapp.chatease.app/numeros', {
            headers: { Authorization: `Bearer ${user.token}` }
        })
        numeros.value = response.data
    } catch (error) {
        console.error('Erro ao buscar números cadastrados:', error)
        statusMessage.value = 'Erro ao buscar números cadastrados.'
    }
}

const handleSubmit = async () => {
    try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (isUpdate.value) {
            await axios.put(
                `https://whatsapp.chatease.app/numeros/${editingNumeroId.value}`,
                form.value,
                { headers: { Authorization: `Bearer ${user.token}` } }
            )
            statusMessage.value = 'Número atualizado com sucesso!'
        } else {
            await axios.post('https://whatsapp.chatease.app/numeros', form.value, {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            statusMessage.value = 'Número cadastrado com sucesso!'
        }
        form.value = { numero: '', empresaId: '' }
        isUpdate.value = false
        editingNumeroId.value = ''
        closeForm()
        await fetchNumeros()
    } catch (error) {
        console.error('Erro ao processar a solicitação:', error)
        statusMessage.value = 'Erro ao processar a solicitação.'
    }
}

const openCreateForm = () => {
    isUpdate.value = false
    editingNumeroId.value = ''
    form.value = { numero: '', empresaId: '' }
    if (!isAdmin.value) fetchUserEmpresa()
    showForm.value = true
}

const closeForm = () => {
    showForm.value = false
    isUpdate.value = false
    editingNumeroId.value = ''
    form.value = { numero: '', empresaId: '' }
}

const editNumero = numero => {
    form.value = { numero: numero.numero, empresaId: numero.empresaId }
    isUpdate.value = true
    editingNumeroId.value = numero.id
    showForm.value = true
}

const confirmDeleteNumero = id => {
    if (confirm('Tem certeza que deseja deletar este número?')) {
        deleteNumero(id)
    }
}

const deleteNumero = async id => {
    try {
        const user = JSON.parse(localStorage.getItem('user'))
        await axios.delete(`https://whatsapp.chatease.app/numeros/${id}`, {
            headers: { Authorization: `Bearer ${user.token}` }
        })
        statusMessage.value = 'Número deletado com sucesso!'
        await fetchNumeros()
    } catch (error) {
        statusMessage.value = 'Erro ao deletar número.'
        console.error('Erro ao deletar número:', error)
    }
}

const getEmpresaNome = empresaId => {
    const empresa = empresas.value.find(e => e.id === empresaId)
    return empresa ? empresa.nome : 'Desconhecida'
}

const numerosFiltrados = computed(() => {
    const termo = termoPesquisa.value.toLowerCase()
    return numeros.value.filter(n =>
        n.numero.toLowerCase().includes(termo) ||
        getEmpresaNome(n.empresaId).toLowerCase().includes(termo)
    )
})

onMounted(async () => {
    await checkUserRole()
    if (!isAdmin.value) await fetchUserEmpresa()
    await fetchEmpresas()
    await fetchNumeros()
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>