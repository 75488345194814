<template>
    <div class="max-w-7xl mx-auto px-4 py-8">
        <h1
            class="block w-full text-center text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg bg-gradient-to-r from-gray-500 to-blue-500 text-transparent bg-clip-text">
            Dashboard do Admin</h1>
        <br />
        <p v-if="!isAdmin" class="text-lg text-red-600 mb-8 text-center">Você não tem permissão para acessar esta
            página.</p>
        <div v-if="isAdmin" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div class="bg-white p-2 shadow rounded-lg flex items-center">
                <font-awesome-icon icon="question-circle" class="text-2xl text-blue-600 mr-2" />
                <div>
                    <p class="text-sm text-gray-800 mb-1">Perguntas</p>
                    <p class="text-lg font-semibold text-blue-600">{{ perguntasCount }}</p>
                </div>
            </div>
            <div class="bg-white p-2 shadow rounded-lg flex items-center">
                <font-awesome-icon icon="building" class="text-2xl text-blue-600 mr-2" />
                <div>
                    <p class="text-sm text-gray-800 mb-1">Empresas</p>
                    <p class="text-lg font-semibold text-blue-600">{{ empresasCount }}</p>
                </div>
            </div>
            <div class="bg-white p-2 shadow rounded-lg flex items-center">
                <font-awesome-icon icon="phone" class="text-2xl text-blue-600 mr-2" />
                <div>
                    <p class="text-sm text-gray-800 mb-1">Números</p>
                    <p class="text-lg font-semibold text-blue-600">{{ sessoesAbertasCount }}</p>
                </div>
            </div>
            <div class="bg-white p-2 shadow rounded-lg flex items-center">
                <font-awesome-icon icon="user" class="text-2xl text-blue-600 mr-2" />
                <div>
                    <p class="text-sm text-gray-800 mb-1">Usuários</p>
                    <p class="text-lg font-semibold text-blue-600">{{ usuariosAtivosCount }}</p>
                </div>
            </div>
        </div>
        <div v-if="isAdmin" class="mt-8">
            <empresa-crescimento-chart :empresas-data="empresasData" />
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import EmpresaCrescimentoChart from './EmpresaCrescimentoChart.vue';

const perguntasCount = ref(0);
const empresasCount = ref(0);
const sessoesAbertasCount = ref(0);
const usuariosAtivosCount = ref(0);
const isAdmin = ref(false);
const empresasData = ref([]);

const fetchDashboardData = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || !user.token) {
        console.error('Usuário não autenticado');
        return;
    }

    const headers = { Authorization: `Bearer ${user.token}` };

    try {
        const authResponse = await axios.get('https://whatsapp.chatease.app/auth/me', { headers });
        isAdmin.value = authResponse.data.role === 'ADMIN';

        if (!isAdmin.value) {
            console.error('Usuário não tem permissão de administrador');
            return;
        }

        const [perguntasRes, empresasRes, usersRes, numerosRes] = await Promise.all([
            axios.get('https://whatsapp.chatease.app/perguntas', { headers }),
            axios.get('https://whatsapp.chatease.app/empresas', { headers }),
            axios.get('https://whatsapp.chatease.app/users', { headers }),
            axios.get('https://whatsapp.chatease.app/numeros', { headers }),
        ]);

        sessoesAbertasCount.value = numerosRes.data.length;
        usuariosAtivosCount.value = usersRes.data.length;
        perguntasCount.value = perguntasRes.data.length;
        empresasCount.value = empresasRes.data.length;

        const empresasGrowthData = empresasRes.data.map((empresa, index) => ({
            date: new Date(empresa.createdAt).toLocaleDateString(),
            count: index + 1
        }));
        empresasData.value = empresasGrowthData;
    } catch (error) {
        console.error('Erro ao buscar dados do dashboard:', error);
    }
};

onMounted(() => {
    fetchDashboardData();
});
</script>