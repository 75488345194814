<template>
    <div
        class="w-full max-w-6xl bg-gradient-to-br from-gray-500 to-blue-600 shadow-lg rounded-lg p-6 sm:p-8 mx-auto my-10 text-center text-white">
        <div class="flex justify-center mb-6">
            <font-awesome-icon icon="robot" class="text-6xl" />
        </div>
        <h2 class="text-2xl sm:text-4xl font-bold mb-4">
            Seja Bem-vindo ao <span class="text-yellow-300">ChatEase</span>
        </h2>
        <p class="text-sm sm:text-lg text-gray-200 mb-6">
            Conecte-se ao WhatsApp e automatize as respostas para seus clientes com facilidade e eficiência.
        </p>
        <div class="overflow-hidden rounded-lg shadow-md">
            <img src="https://diariodocomercio.com.br/wp-content/uploads/2024/07/AI-inteligencia-artificial-gestao.jpg"
                alt="Chatbot AI"
                class="w-full h-40 sm:h-56 object-cover transform transition-transform duration-500 hover:scale-105" />
        </div>
    </div>
</template>

  
  <style scoped>
.shadow-lg {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.transition-transform {
    transition: transform 0.3s ease-in-out;
}

.hover\:scale-105:hover {
    transform: scale(1.05);
}
</style>
