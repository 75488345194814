<template>
    <div class="flex flex-col min-h-screen bg-gray-50 text-gray-800">
        <header class="flex items-center justify-between px-6 py-4 bg-blue-200 border-b border-gray-200 shadow-sm">
            <button @click="toggleMobileSidebar"
                class="block md:hidden text-gray-500 hover:text-gray-700 focus:outline-none mr-4">
                <font-awesome-icon :icon="mobileSidebarOpen ? 'times' : 'bars'" size="lg" />
            </button>

            <h1 class="text-lg font-semibold flex items-center">
                <font-awesome-icon icon="robot" class="text-blue-500 mr-3" />
                <span>Administrador ChatEase</span>
            </h1>

            <nav>
                <router-link to="/">
                    <button @click="logout"
                        class="inline-flex items-center px-4 py-2 bg-red-500 text-white text-sm font-medium rounded-md hover:bg-red-600 transition-colors">
                        <font-awesome-icon icon="sign-in-alt" class="mr-2" />
                        Sair
                    </button>
                </router-link>
            </nav>
        </header>

        <div class="flex flex-1">
            <aside class="hidden md:flex md:flex-col w-64 bg-white border-r border-gray-200 py-6 px-4 flex-shrink-0">
                <div class="mb-8">
                    <h2 class="font-bold text-gray-600 uppercase tracking-wider text-sm">
                        Painel de Controle
                    </h2>
                </div>
                <nav class="flex-1 space-y-1">
                    <router-link to="/admin/dashboard"
                        class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-blue-50 hover:text-blue-600 transition-colors"
                        active-class="bg-blue-100 text-blue-600">
                        <font-awesome-icon icon="home" class="mr-3" />
                        Início
                    </router-link>

                    <router-link to="/admin/usuarios"
                        class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-blue-50 hover:text-blue-600 transition-colors"
                        active-class="bg-blue-100 text-blue-600">
                        <font-awesome-icon icon="user" class="mr-3" />
                        Usuários
                    </router-link>

                    <router-link to="/admin/empresas"
                        class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-blue-50 hover:text-blue-600 transition-colors"
                        active-class="bg-blue-100 text-blue-600">
                        <font-awesome-icon icon="building" class="mr-3" />
                        Empresas
                    </router-link>

                    <div>
                        <button @click="toggleBotMenu"
                            class="w-full flex justify-between items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-blue-50 hover:text-blue-600 transition-colors focus:outline-none">
                            <span class="flex items-center">
                                <font-awesome-icon icon="robot" class="mr-3" />
                                Bot WhatsApp
                            </span>
                            <font-awesome-icon :icon="botMenuOpen ? 'chevron-up' : 'chevron-down'" />
                        </button>
                        <div v-if="botMenuOpen" class="ml-4 mt-1 space-y-1">
                            <router-link to="/admin/numeros"
                                class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-blue-50 hover:text-blue-600 transition-colors"
                                active-class="bg-blue-100 text-blue-600">
                                <font-awesome-icon icon="phone" class="mr-3" />
                                Vincular Números
                            </router-link>
                            <router-link to="/admin/session"
                                class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-blue-50 hover:text-blue-600 transition-colors"
                                active-class="bg-blue-100 text-blue-600">
                                <font-awesome-icon icon="sign-in-alt" class="mr-3" />
                                Iniciar Sessão
                            </router-link>
                            <router-link to="/admin/perguntas"
                                class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-blue-50 hover:text-blue-600 transition-colors"
                                active-class="bg-blue-100 text-blue-600">
                                <font-awesome-icon icon="question-circle" class="mr-3" />
                                Gerenciar Perguntas
                            </router-link>
                            <router-link to="/admin/perguntas-fixas"
                                class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-blue-50 hover:text-blue-600 transition-colors"
                                active-class="bg-blue-100 text-blue-600">
                                <font-awesome-icon icon="question-circle" class="mr-3" />
                                Perguntas Fixas
                            </router-link>
                        </div>
                    </div>
                </nav>
            </aside>

            <transition name="slide">
                <aside v-if="mobileSidebarOpen"
                    class="fixed inset-y-0 left-0 z-50 w-64 bg-white border-r border-gray-200 py-6 px-4 flex flex-col md:hidden">
                    <div class="flex items-center justify-between mb-8">
                        <h2 class="font-bold text-gray-600 uppercase tracking-wider text-sm">
                            Painel de Controle
                        </h2>
                        <button @click="toggleMobileSidebar"
                            class="text-gray-500 hover:text-gray-700 focus:outline-none">
                            <font-awesome-icon icon="times" size="lg" />
                        </button>
                    </div>
                    <nav class="flex-1 space-y-1">
                        <router-link to="/admin/dashboard"
                            class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-blue-50 hover:text-blue-600 transition-colors"
                            active-class="bg-blue-100 text-blue-600" @click="toggleMobileSidebar">
                            <font-awesome-icon icon="home" class="mr-3" />
                            Início
                        </router-link>

                        <router-link to="/admin/usuarios"
                            class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-blue-50 hover:text-blue-600 transition-colors"
                            active-class="bg-blue-100 text-blue-600" @click="toggleMobileSidebar">
                            <font-awesome-icon icon="user" class="mr-3" />
                            Usuários
                        </router-link>

                        <router-link to="/admin/empresas"
                            class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-blue-50 hover:text-blue-600 transition-colors"
                            active-class="bg-blue-100 text-blue-600" @click="toggleMobileSidebar">
                            <font-awesome-icon icon="building" class="mr-3" />
                            Empresas
                        </router-link>

                        <div>
                            <button @click="toggleBotMenu"
                                class="w-full flex justify-between items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-blue-50 hover:text-blue-600 transition-colors focus:outline-none">
                                <span class="flex items-center">
                                    <font-awesome-icon icon="robot" class="mr-3" />
                                    Bot WhatsApp
                                </span>
                                <font-awesome-icon :icon="botMenuOpen ? 'chevron-up' : 'chevron-down'" />
                            </button>
                            <div v-if="botMenuOpen" class="ml-4 mt-1 space-y-1">
                                <router-link to="/admin/numeros"
                                    class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-blue-50 hover:text-blue-600 transition-colors"
                                    active-class="bg-blue-100 text-blue-600" @click="toggleMobileSidebar">
                                    <font-awesome-icon icon="phone" class="mr-3" />
                                    Vincular Números
                                </router-link>
                                <router-link to="/admin/session"
                                    class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-blue-50 hover:text-blue-600 transition-colors"
                                    active-class="bg-blue-100 text-blue-600" @click="toggleMobileSidebar">
                                    <font-awesome-icon icon="sign-in-alt" class="mr-3" />
                                    Iniciar Sessão
                                </router-link>
                                <router-link to="/admin/perguntas"
                                    class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-blue-50 hover:text-blue-600 transition-colors"
                                    active-class="bg-blue-100 text-blue-600" @click="toggleMobileSidebar">
                                    <font-awesome-icon icon="question-circle" class="mr-3" />
                                    Gerenciar Perguntas
                                </router-link>
                                <router-link to="/admin/perguntas-fixas"
                                    class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-blue-50 hover:text-blue-600 transition-colors"
                                    active-class="bg-blue-100 text-blue-600" @click="toggleMobileSidebar">
                                    <font-awesome-icon icon="question-circle" class="mr-3" />
                                    Perguntas Fixas
                                </router-link>
                            </div>
                        </div>
                    </nav>
                </aside>
            </transition>

            <main class="flex-grow p-6">
                <router-view />
            </main>
        </div>

        <footer class="bg-blue-200 border-t border-gray-200 py-4 text-center text-sm text-gray-500">
            © 2024 ChatEase. Todos os direitos reservados.
        </footer>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const botMenuOpen = ref(false);
const mobileSidebarOpen = ref(false);

const toggleBotMenu = () => {
    botMenuOpen.value = !botMenuOpen.value;
};

const toggleMobileSidebar = () => {
    mobileSidebarOpen.value = !mobileSidebarOpen.value;
};

const logout = () => {
    localStorage.removeItem('user');
    router.push('/');
};
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
    transition: transform 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(-100%);
}
</style>