<template>
    <div class="min-h-screen bg-gray-50 text-gray-800 p-8">
        <h1 class="block w-full text-center text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg
               bg-gradient-to-r from-gray-500 to-blue-500 text-transparent bg-clip-text">
            Cadastrar Empresa
        </h1>

        <div class="flex flex-col md:flex-row justify-between items-center mt-4 gap-4">
            <button v-if="isAdmin && !showForm" @click="openForm" class="bg-gray-600 text-white px-6 py-3 rounded-lg font-semibold
                 transition-colors duration-300 hover:bg-gray-700">
                Cadastrar Nova Empresa
            </button>
            <div class="w-full md:w-1/3">
                <input type="text" v-model="searchTerm" placeholder="Pesquisar Empresa" class="w-full p-3 border border-gray-300 rounded-md shadow-sm
                   focus:outline-none focus:ring-blue-500 focus:border-blue-500" />
            </div>
        </div>

        <div v-if="filteredEmpresas.length" class="overflow-x-auto mt-6">
            <table class="min-w-full bg-white border border-gray-200">
                <thead class="bg-gray-100 border-b">
                    <tr>
                        <th class="py-3 px-4 text-left text-sm font-medium text-gray-700">Nome</th>
                        <th class="py-3 px-4 text-left text-sm font-medium text-gray-700">Usuário</th>
                        <th class="py-3 px-4 text-left text-sm font-medium text-gray-700">Status</th>
                        <th class="py-3 px-4 text-left text-sm font-medium text-gray-700">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="empresa in filteredEmpresas" :key="empresa.id"
                        class="border-b hover:bg-gray-50 transition-colors">
                        <td class="py-3 px-4">{{ empresa.nome }}</td>
                        <td class="py-3 px-4">
                            {{ getUserName(empresa.userId) }}
                        </td>
                        <td class="py-3 px-4">
                            <span :class="empresa.status ? 'text-green-600' : 'text-red-600'">
                                {{ empresa.status ? 'Ativo' : 'Inativo' }}
                            </span>
                        </td>
                        <td class="py-3 px-4">
                            <div class="flex space-x-4 items-center">
                                <button @click="editEmpresa(empresa)" class="text-blue-600 hover:underline">
                                    Editar
                                </button>
                                <button @click="confirmDeleteEmpresa(empresa.id)" class="text-red-600 hover:underline">
                                    Deletar
                                </button>
                                <label class="inline-flex relative items-center cursor-pointer">
                                    <input type="checkbox" class="sr-only peer" :checked="empresa.status"
                                        @change="toggleEmpresaStatus(empresa)" />
                                    <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300
                             dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full
                             peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5
                             after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full
                             after:h-5 after:w-5 after:transition-all peer-checked:bg-gray-600"></div>
                                </label>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <p v-else class="mt-4 text-center text-gray-600">
            Nenhuma empresa encontrada.
        </p>

        <transition name="fade">
            <div v-if="showForm" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div
                    class="bg-white w-full max-w-3xl mx-4 p-6 rounded-lg shadow-lg relative max-h-[90vh] overflow-y-auto">
                    <button @click="closeForm"
                        class="absolute top-3 right-3 text-gray-600 hover:text-gray-900 text-2xl">
                        &times;
                    </button>
                    <h2 class="text-center text-2xl font-bold mb-4
                     bg-gradient-to-r from-gray-500 to-blue-500 text-transparent bg-clip-text">
                        {{ isEditing ? 'Atualizar Empresa' : 'Cadastrar Empresa' }}
                    </h2>
                    <form @submit.prevent="handleSubmit" class="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div class="md:col-span-2">
                            <label for="nome" class="block text-sm font-medium text-gray-700">
                                Nome da Empresa
                            </label>
                            <input id="nome" v-model="nome" type="text" required class="mt-1 p-3 block w-full border border-gray-300 rounded-md shadow-sm
                         focus:outline-none focus:ring-blue-500 focus:border-blue-500" />
                        </div>
                        <div>
                            <label for="userId" class="block text-sm font-medium text-gray-700">
                                Usuário
                            </label>
                            <select id="userId" v-model="userId" required class="mt-1 p-3 block w-full border border-gray-300 rounded-md shadow-sm
                         focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                                <option value="" disabled>Selecione um usuário</option>
                                <option v-for="user in users" :key="user.id" :value="user.id">
                                    {{ user.name }}
                                </option>
                            </select>
                        </div>
                        <div class="md:col-span-2">
                            <label for="respostaPadrao" class="block text-sm font-medium text-gray-700">
                                Resposta Padrão
                            </label>
                            <textarea id="respostaPadrao" v-model="respostaPadrao" required class="mt-1 p-3 block w-full border border-gray-300 rounded-md shadow-sm
                         focus:outline-none focus:ring-blue-500 focus:border-blue-500"></textarea>
                        </div>
                        <div v-if="!isEditing">
                            <label for="novoNumero" class="block text-sm font-medium text-gray-700">
                                Número de Telefone
                            </label>
                            <input id="novoNumero" v-model="novoNumero" type="text" class="mt-1 p-3 block w-full border border-gray-300 rounded-md shadow-sm
                         focus:outline-none focus:ring-blue-500 focus:border-blue-500" />
                            <button type="button" @click="addNumero" class="mt-2 w-full bg-green-600 text-white px-4 py-2 rounded-md
                         hover:bg-green-700 transition duration-300 font-semibold">
                                Adicionar Número
                            </button>
                        </div>
                        <div v-if="!isEditing && numeros.length" class="md:col-span-2">
                            <ul class="space-y-2">
                                <li v-for="(numero, index) in numeros" :key="index"
                                    class="bg-gray-100 p-2 rounded-md flex justify-between items-center">
                                    <span>{{ numero }}</span>
                                    <button @click="removeNumero(index)" class="text-red-600 hover:underline">
                                        Remover
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div v-for="(pergunta, index) in perguntasFixas" :key="index" class="md:col-span-2">
                            <label :for="'texto' + index" class="block text-sm font-medium text-gray-700">
                                Texto da Pergunta Fixa
                            </label>
                            <input :id="'texto' + index" v-model="pergunta.texto" type="text" class="mt-1 p-3 block w-full border border-gray-300 rounded-md shadow-sm
                         focus:outline-none focus:ring-blue-500 focus:border-blue-500" />
                            <label :for="'resposta' + index" class="block text-sm font-medium text-gray-700 mt-2">
                                Resposta
                            </label>
                            <textarea :id="'resposta' + index" v-model="pergunta.resposta" class="mt-1 p-3 block w-full border border-gray-300 rounded-md shadow-sm
                         focus:outline-none focus:ring-blue-500 focus:border-blue-500"></textarea>
                        </div>
                        <div class="md:col-span-2">
                            <button type="submit" class="w-full bg-gray-600 text-white px-4 py-3 rounded-md
                         hover:bg-gray-700 transition duration-300 font-semibold">
                                {{ isEditing ? 'Atualizar Empresa' : 'Cadastrar Empresa' }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </transition>

        <p v-if="statusMessage" class="mt-4 text-center text-gray-700 font-medium">
            {{ statusMessage }}
        </p>
    </div>
</template>
  
  <script setup>
import { ref, computed, onMounted } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'

const API_BASE = 'https://whatsapp.chatease.app'
const nome = ref('')
const userId = ref('')
const respostaPadrao = ref('')
const novoNumero = ref('')
const numeros = ref([])
const statusMessage = ref('')
const userRole = ref('')
const isAdmin = ref(false)
const empresas = ref([])
const users = ref([])
const searchTerm = ref('')
const isEditing = ref(false)
const editingEmpresaId = ref('')
const showForm = ref(false)
const perguntasFixas = ref([])
const router = useRouter()

const checkUserRole = async () => {
    try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.token) {
            const response = await axios.get(`${API_BASE}/auth/me`, {
                headers: { Authorization: `Bearer ${user.token}` },
            })
            userRole.value = response.data.role
            isAdmin.value = userRole.value === 'ADMIN'
            if (!isAdmin.value) {
                statusMessage.value = 'Você não tem permissão para cadastrar empresas.'
                setTimeout(() => {
                    router.push('/')
                }, 2000)
            } else {
                fetchEmpresas(user.token)
                fetchUsers(user.token)
                fetchPerguntasFixas(user.token)
            }
        } else {
            statusMessage.value = 'Você precisa estar logado.'
            setTimeout(() => {
                router.push('/')
            }, 2000)
        }
    } catch (error) {
        statusMessage.value = 'Erro ao verificar papel do usuário.'
        setTimeout(() => {
            router.push('/')
        }, 2000)
    }
}

const fetchEmpresas = async (token) => {
    try {
        const response = await axios.get(`${API_BASE}/empresas`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        empresas.value = response.data
    } catch (error) {
        statusMessage.value = 'Erro ao buscar empresas.'
    }
}

const fetchUsers = async (token) => {
    try {
        const response = await axios.get(`${API_BASE}/users`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        users.value = response.data
    } catch (error) {
        statusMessage.value = 'Erro ao buscar usuários.'
    }
}

const fetchPerguntasFixas = async (token) => {
    try {
        const response = await axios.get(`${API_BASE}/perguntas-fixas`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        perguntasFixas.value = response.data.map(pergunta => ({
            texto: pergunta.texto,
            resposta: pergunta.resposta
        }))
    } catch (error) {
        statusMessage.value = 'Erro ao buscar perguntas fixas.'
    }
}

const filteredEmpresas = computed(() => {
    const term = searchTerm.value.toLowerCase()
    return empresas.value.filter(empresa =>
        empresa.nome.toLowerCase().includes(term)
    )
})

const getUserName = (theUserId) => {
    const user = users.value.find(u => u.id === theUserId)
    return user ? user.name : '—'
}

const openForm = () => {
    isEditing.value = false
    editingEmpresaId.value = ''
    nome.value = ''
    userId.value = ''
    respostaPadrao.value = ''
    novoNumero.value = ''
    numeros.value = []
    const user = JSON.parse(localStorage.getItem('user'))
    if (user && user.token && userRole.value === 'ADMIN') {
        fetchPerguntasFixas(user.token)
    }
    showForm.value = true
}

const closeForm = () => {
    showForm.value = false
}

const addNumero = () => {
    if (novoNumero.value) {
        numeros.value.push(novoNumero.value.trim())
        novoNumero.value = ''
    }
}

const removeNumero = (index) => {
    numeros.value.splice(index, 1)
}

const editEmpresa = (empresa) => {
    nome.value = empresa.nome
    userId.value = empresa.userId
    respostaPadrao.value = empresa.respostaPadrao
    perguntasFixas.value = empresa.perguntas || []
    isEditing.value = true
    editingEmpresaId.value = empresa.id
    showForm.value = true
}

const handleSubmit = async () => {
    try {
        const user = JSON.parse(localStorage.getItem('user'))
        const token = user.token
        if (isEditing.value) {
            await axios.put(
                `${API_BASE}/empresas/${editingEmpresaId.value}`,
                {
                    nome: nome.value,
                    userId: userId.value,
                    respostaPadrao: respostaPadrao.value,
                    perguntas: perguntasFixas.value
                },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            statusMessage.value = 'Empresa atualizada com sucesso!'
        } else {
            const empresaResponse = await axios.post(
                `${API_BASE}/empresas`,
                {
                    nome: nome.value,
                    userId: userId.value,
                    respostaPadrao: respostaPadrao.value,
                    perguntas: perguntasFixas.value
                },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            const empresaId = empresaResponse.data.id
            for (const numero of numeros.value) {
                await axios.post(
                    `${API_BASE}/numeros`,
                    { numero, empresaId },
                    { headers: { Authorization: `Bearer ${token}` } }
                )
            }
            statusMessage.value = 'Empresa cadastrada com sucesso!'
        }
        nome.value = ''
        userId.value = ''
        respostaPadrao.value = ''
        novoNumero.value = ''
        numeros.value = []
        perguntasFixas.value = []
        isEditing.value = false
        editingEmpresaId.value = ''
        showForm.value = false
        fetchEmpresas(token)
    } catch (error) {
        statusMessage.value = 'Erro ao processar a solicitação.'
    }
}

const confirmDeleteEmpresa = (id) => {
    if (confirm('Tem certeza que deseja deletar esta empresa?')) {
        deleteEmpresa(id)
    }
}

const deleteEmpresa = async (id) => {
    try {
        const user = JSON.parse(localStorage.getItem('user'))
        await axios.delete(`${API_BASE}/empresas/${id}`, {
            headers: { Authorization: `Bearer ${user.token}` },
        })
        statusMessage.value = 'Empresa deletada com sucesso!'
        fetchEmpresas(user.token)
    } catch (error) {
        statusMessage.value = 'Erro ao deletar empresa.'
    }
}

const toggleEmpresaStatus = async (empresa) => {
    try {
        const user = JSON.parse(localStorage.getItem('user'))
        const token = user.token
        const newStatus = !empresa.status
        await axios.patch(
            `${API_BASE}/empresas/${empresa.id}/status`,
            { status: newStatus },
            { headers: { Authorization: `Bearer ${token}` } }
        )
        empresa.status = newStatus
        statusMessage.value = `Empresa ${newStatus ? 'ativada' : 'desativada'} com sucesso!`
    } catch (error) {
        statusMessage.value = 'Erro ao atualizar o status da empresa.'
    }
}

onMounted(() => {
    checkUserRole()
})
</script>
  
  <style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
  