<template>
    <div class="w-full min-h-screen bg-gray-50 text-gray-800 p-8">
        <h1
            class="block w-full text-center text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg bg-gradient-to-r from-gray-500 to-blue-500 text-transparent bg-clip-text">
            Gerenciador de Perguntas
        </h1>
        <br />

        <div class="flex justify-start mb-6">
            <button v-if="!showForm" @click="openCreateForm"
                class="bg-gray-600 text-white px-6 py-3 rounded-lg font-semibold transition-colors duration-300 hover:bg-gray-700">
                {{ isUpdate ? 'Atualizar Pergunta' : 'Adicionar Nova Pergunta' }}
            </button>
        </div>

        <p v-if="statusMessage" class="mt-2 text-center text-red-500">
            {{ statusMessage }}
        </p>

        <div class="my-5 w-full">
            <label for="pesquisaPergunta" class="block text-gray-700 text-sm font-medium mb-2">
                Pesquisar:
            </label>
            <input id="pesquisaPergunta" v-model="termoPesquisa"
                class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Pesquise..." />
        </div>

        <div v-if="perguntasFiltradas.length" class="overflow-x-auto mt-4">
            <table class="min-w-full bg-white border border-gray-200">
                <thead class="bg-gray-100 border-b border-gray-200">
                    <tr>
                        <th class="py-3 px-4 text-left text-sm font-medium text-gray-700">Pergunta</th>
                        <th class="py-3 px-4 text-left text-sm font-medium text-gray-700">Resposta</th>
                        <th v-if="isAdmin" class="py-3 px-4 text-left text-sm font-medium text-gray-700">Empresa</th>
                        <th class="py-3 px-4 text-left text-sm font-medium text-gray-700">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="pergunta in perguntasFiltradas" :key="pergunta.id"
                        class="border-b last:border-0 hover:bg-gray-50 transition-colors">
                        <td class="py-3 px-4 text-gray-800 font-semibold">
                            {{ pergunta.texto }}
                        </td>
                        <td class="py-3 px-4 text-gray-600">
                            {{ pergunta.resposta }}
                        </td>
                        <td v-if="isAdmin" class="py-3 px-4 text-gray-800">
                            {{ getEmpresaNome(pergunta.empresaId) || '—' }}
                        </td>
                        <td class="py-3 px-4">
                            <div class="flex space-x-4 text-sm">
                                <button @click="editPergunta(pergunta)" class="text-blue-600 hover:underline">
                                    Editar
                                </button>
                                <button @click="confirmDeletePergunta(pergunta.id)"
                                    class="text-red-600 hover:underline">
                                    Excluir
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <p v-else class="mt-4 text-center text-gray-600">
            Nenhuma pergunta encontrada.
        </p>

        <!-- Modal -->
        <transition name="fade">
            <div v-if="showForm" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div class="bg-white w-full max-w-2xl mx-4 p-6 rounded-lg shadow-lg relative">
                    <button @click="closeForm"
                        class="absolute top-3 right-3 text-gray-600 hover:text-gray-900 text-2xl">
                        &times;
                    </button>
                    <h2
                        class="text-center text-2xl font-bold mb-4 bg-gradient-to-r from-gray-500 to-blue-500 text-transparent bg-clip-text">
                        {{ isUpdate ? 'Atualizar Pergunta' : 'Adicionar Nova Pergunta' }}
                    </h2>

                    <form @submit.prevent="handleSubmit" class="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div v-if="isAdmin">
                            <label for="empresa" class="block text-gray-700 text-sm font-medium">
                                Empresa:
                            </label>
                            <select id="empresa" v-model="form.empresaId"
                                class="mt-2 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                <option disabled value="">Selecione uma empresa</option>
                                <option v-for="empresa in empresas" :key="empresa.id" :value="empresa.id">
                                    {{ empresa.nome }}
                                </option>
                            </select>
                        </div>

                        <div :class="isAdmin ? '' : 'md:col-span-2'">
                            <label for="texto" class="block text-gray-700 text-sm font-medium">
                                Palavra(s)-chave:
                            </label>
                            <input type="text" id="texto" v-model="form.texto"
                                class="mt-2 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                            <p class="text-gray-600 text-xs mt-1">
                                Separe cada palavra-chave por vírgula.
                            </p>
                        </div>

                        <div class="md:col-span-2">
                            <label for="resposta" class="block text-gray-700 text-sm font-medium">
                                Resposta:
                            </label>
                            <textarea id="resposta" v-model="form.resposta"
                                class="mt-2 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"></textarea>
                            <p class="text-gray-600 text-xs mt-1">
                                Use <strong>{cliente}</strong> para inserir o nome do cliente na resposta.
                            </p>
                        </div>

                        <div class="md:col-span-2">
                            <button type="submit"
                                class="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-md w-full transition duration-300">
                                {{ isUpdate ? 'Atualizar Pergunta' : 'Adicionar Pergunta' }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </transition>
    </div>
</template>
  
  <script>
import { ref, computed, onMounted } from 'vue'
import axios from 'axios'

export default {
    setup() {
        const perguntas = ref([])
        const empresas = ref([])
        const form = ref({ texto: '', resposta: '', empresaId: '' })
        const isAdmin = ref(false)
        const termoPesquisa = ref('')
        const isUpdate = ref(false)
        const showForm = ref(false)
        const editingPerguntaId = ref(null)
        const statusMessage = ref('')

        const getAuthHeaders = () => {
            const user = JSON.parse(localStorage.getItem('user'))
            return user && user.token ? { Authorization: `Bearer ${user.token}` } : {}
        }

        const fetchPerguntas = async () => {
            try {
                const response = await axios.get('https://whatsapp.chatease.app/perguntas', {
                    headers: getAuthHeaders()
                })
                perguntas.value = response.data.sort((a, b) => a.texto.localeCompare(b.texto))
            } catch (error) {
                console.error(error)
            }
        }

        const fetchEmpresas = async () => {
            try {
                const response = await axios.get('https://whatsapp.chatease.app/empresas', {
                    headers: getAuthHeaders()
                })
                empresas.value = response.data
            } catch (error) {
                console.error(error)
            }
        }

        const checkAdmin = async () => {
            const user = JSON.parse(localStorage.getItem('user'))
            if (user && user.token) {
                const response = await axios.get('https://whatsapp.chatease.app/auth/me', {
                    headers: { Authorization: `Bearer ${user.token}` }
                })
                isAdmin.value = response.data.role === 'ADMIN'
            }
        }

        const handleSubmit = async () => {
            if (!isAdmin.value) {
                delete form.value.empresaId
            }
            try {
                if (isUpdate.value) {
                    await axios.put(`https://whatsapp.chatease.app/perguntas/${editingPerguntaId.value}`, form.value, {
                        headers: getAuthHeaders()
                    })
                    statusMessage.value = 'Pergunta atualizada com sucesso!'
                } else {
                    await axios.post('https://whatsapp.chatease.app/perguntas', form.value, {
                        headers: getAuthHeaders()
                    })
                    statusMessage.value = 'Pergunta adicionada com sucesso!'
                }
                resetForm()
            } catch (error) {
                statusMessage.value = `Erro ao processar a solicitação: ${error.message}`
            }
        }

        const resetForm = () => {
            form.value = { texto: '', resposta: '', empresaId: '' }
            isUpdate.value = false
            editingPerguntaId.value = null
            showForm.value = false
            fetchPerguntas()
        }

        const openCreateForm = () => {
            isUpdate.value = false
            editingPerguntaId.value = null
            form.value = { texto: '', resposta: '', empresaId: isAdmin.value ? '' : form.value.empresaId }
            showForm.value = true
        }

        const editPergunta = (pergunta) => {
            isUpdate.value = true
            editingPerguntaId.value = pergunta.id
            form.value = {
                texto: pergunta.texto,
                resposta: pergunta.resposta,
                empresaId: pergunta.empresaId
            }
            showForm.value = true
        }

        const confirmDeletePergunta = (id) => {
            if (confirm('Tem certeza que deseja excluir esta pergunta?')) {
                deletePergunta(id)
            }
        }

        const deletePergunta = async (id) => {
            try {
                await axios.delete(`https://whatsapp.chatease.app/perguntas/${id}`, {
                    headers: getAuthHeaders()
                })
                statusMessage.value = 'Pergunta excluída com sucesso!'
                fetchPerguntas()
            } catch (error) {
                statusMessage.value = 'Erro ao excluir pergunta.'
            }
        }

        const closeForm = () => {
            showForm.value = false
            isUpdate.value = false
            editingPerguntaId.value = null
            form.value = { texto: '', resposta: '', empresaId: '' }
        }

        const getEmpresaNome = (empresaId) => {
            const empresa = empresas.value.find(e => e.id === empresaId)
            return empresa ? empresa.nome : ''
        }

        const perguntasFiltradas = computed(() => {
            const termos = termoPesquisa.value
                .toLowerCase()
                .split(',')
                .map(t => t.trim())
                .filter(Boolean)
            return perguntas.value.filter(p => {
                return termos.every(termo =>
                    p.texto.toLowerCase().includes(termo) ||
                    p.resposta.toLowerCase().includes(termo) ||
                    getEmpresaNome(p.empresaId).toLowerCase().includes(termo)
                )
            })
        })

        onMounted(() => {
            fetchPerguntas()
            fetchEmpresas()
            checkAdmin()
        })

        return {
            perguntas,
            empresas,
            form,
            isAdmin,
            termoPesquisa,
            isUpdate,
            showForm,
            editingPerguntaId,
            statusMessage,
            perguntasFiltradas,
            openCreateForm,
            editPergunta,
            confirmDeletePergunta,
            handleSubmit,
            closeForm,
            getEmpresaNome
        }
    }
}
</script>
  
  <style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
  